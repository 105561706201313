<template>
  <div class="c-input-number">
    <LabelSimpleComponent :name="name">
      {{ label }}
    </LabelSimpleComponent>

    <input
      :id="name"
      v-model="value"
      :placeholder="currency(0)"
      class="c-input-number__input"
      type="text"
      :name="name"
      @blur="handleBlur"
      @focus="handleFocus"
    />
  </div>
</template>

<script setup>
import LabelSimpleComponent from "./LabelSimpleComponent.vue";
import { currency } from "../../filters";

import { ref, onMounted } from "vue";

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    default: "",
  },
  initialValue: {
    type: Number,
    default: null,
  },
});

const emit = defineEmits(["update:modelValue"]);

const value = ref("");

onMounted(() => {
  if (props.initialValue !== null) {
    value.value = currency(props.initialValue);
  }
});

const handleBlur = () => {
  value.value = value.value.replace(/(\D.*)/g, "");

  if (value.value === "") {
    emit("update:modelValue", null);
    return;
  }

  emit("update:modelValue", +value.value);
  value.value = currency(value.value);
};

const handleFocus = () => {
  value.value = value.value.replace(",00", "").replace(/\D/g, "");
};
</script>

<style lang="scss">
.c-input-number {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  &__input {
    height: 48px;
    line-height: 48px;
    padding-left: 16px;
    padding-right: 16px;
    border: 1px solid $grey;
    border-radius: 6px;
  }
}
</style>
