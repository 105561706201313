<template>
  <ais-hits v-slot="{ items }" :transform-items="handleTransformItems">
    <overview-results
      v-if="items && items.length"
      :items="items"
      :is-single-column="isSingleColumn"
    />

    <div v-else class="u-text-center u-margin-top-small">
      <p class="t-text t-text--title">
        {{ noResultsMessage }}
      </p>
    </div>
  </ais-hits>
</template>

<script setup>
import { t } from "../../filters";

const props = defineProps({
  extraBlocks: {
    type: Array,
    default: () => [],
  },
  noResultsMessage: {
    type: String,
    default: t("No results found"),
  },
  isSingleColumn: {
    default: false,
    type: Boolean,
  },
});

const handleTransformItems = (items) => {
  if (!props.extraBlocks) return items;

  props.extraBlocks.forEach((element) => {
    const index = items.findIndex(
      (item) => item.__position === element.position
    );

    if (index >= 0) items.splice(index, 0, element);
  });

  return items;
};
</script>
