<template>
  <div>
    <div class="form__label-group">
      <slot name="label"></slot>

      <span
        v-if="slots.instructions"
        class="form__instructions-icon"
        :class="{ 'form__instructions-icon--open': instructionsOpen }"
        @click="handleClick"
        >i</span
      >
    </div>
    <div
      v-if="slots.instructions && instructionsOpen"
      class="form__instructions"
    >
      <slot name="instructions"></slot>
      <span class="form__instructions-close" @click="handleCloseClick">x</span>
    </div>
  </div>
</template>

<script setup>
import { ref, useSlots } from "vue";

const instructionsOpen = ref(false);
const slots = useSlots();

const handleCloseClick = () => {
  instructionsOpen.value = false;
};
const handleClick = () => {
  console.log(slots);
  instructionsOpen.value = !instructionsOpen.value;
};
</script>
