<template>
  <div v-if="open" class="c-modal" @click="handleClickModal">
    <div class="c-modal__content">
      <slot :close-modal="closeModal" />
    </div>
    <button class="c-modal__close">✕</button>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },
});

const open = ref(false);

const openModal = () => {
  open.value = true;
};

const closeModal = () => {
  open.value = false;
};

const handleClickModal = (event) => {
  const elementCloseList = ["c-modal", "c-modal__close"];

  if (
    elementCloseList.some((item) => [...event.target.classList].includes(item))
  ) {
    closeModal();
  }
};

onMounted(() => {
  open.value = props.isOpen;
  if (props.isOpen) openModal();
});
</script>

<style lang="scss" scoped>
.c-modal {
  animation: fadeIn 0.4s ease 0s 1;
  background-color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
  height: 100vh;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 12;
  width: 100%;
  display: flex;
  justify-content: center;

  &__content {
    animation: 0.4s ease 0s 1 slideInFromBottom;
  }

  &__close {
    cursor: pointer;
    z-index: 16;
    font-weight: 600;
    position: absolute;
    right: 0;
    top: 0;
    color: $grey-light;
    margin-right: 20px;
    margin-top: 20px;
    opacity: 0.7;
    background: transparent;
    border: none;
    font-size: 40px;
    &:hover {
      opacity: 1;
    }
  }
}
</style>
