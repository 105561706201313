<template>
  <div class="form__files">
    <ul v-if="files.length" class="form__files-list">
      <li
        v-for="(item, index) in files"
        :key="index"
        class="form__files-list-item"
      >
        {{ item.name }}
      </li>
    </ul>
    <ul v-else class="form__files-list">
      <li class="form__files-list-item" @click="myFiles.click()"></li>
    </ul>
    <input
      ref="myFiles"
      type="file"
      :name="inputName"
      class="form__files-input"
      :multiple="multiple"
      @change="previewFiles"
    />
    <div class="form__files-button">
      <button
        type="button"
        class="button is-transparent w-100% less-padding"
        @click="myFiles.click()"
      >
        {{ buttonText }}
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onBeforeMount } from "vue";

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  value: {
    type: Array,
    required: true,
  },
  fileCount: {
    type: Number,
    required: true,
  },
  fileKinds: {
    type: Array,
    required: true,
  },
  labels: {
    type: Object,
    required: true,
  },
});

const files = ref([]);
const myFiles = ref(null);

const multiple = computed(() => {
  return props.fileCount > 1;
});

const inputName = computed(() => {
  return `${props.name}[]`;
});

const buttonText = computed(() => {
  if (!files.value.length && multiple.value) {
    return props.labels.textChooseMulti;
  }

  if (files.value.length >= 2 && multiple.value) {
    return props.labels.textEditMulti;
  }

  if (files.value.length) {
    return props.labels.textEditSingle;
  }

  return props.labels.textChooseSingle;
});

onBeforeMount(() => {
  files.value = props.value;
});

const previewFiles = () => {
  files.value = myFiles.value.files;
};
</script>
