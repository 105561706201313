<template>
  <div class="agenda-index__content-list__item">
    <figure class="agenda-index__content-list__image">
      <a :href="listItem.url">
        <img class="object-fit" :src="image" :alt="title" />
      </a>
    </figure>
    <scroll-out-component>
      <div class="agenda-index__title">
        <a
          :href="listItem.url"
          class="link link--hover-black link--hover-underline"
        >
          <h3>{{ listItem.title }}</h3>
        </a>
      </div>
      <div v-if="listItem.dateLocation" class="agenda-index__description">
        <p class="paragraph paragraph--bold">
          {{ listItem.dateLocation }}
        </p>
      </div>
      <div v-if="description" class="agenda-index__description">
        <p class="paragraph">
          {{ description }}
        </p>
      </div>
      <div class="agenda-index__link">
        <a :href="listItem.url" class="text-button__uppercase">{{
          labels.readMore
        }}</a>
      </div>
    </scroll-out-component>
  </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  placeholderImage: {
    type: String,
    required: true,
  },
  listItem: {
    type: Object,
    required: true,
  },
  labels: {
    type: Object,
    required: true,
  },
});

const image = computed(() => {
  return props.listItem.featuredImage
    ? props.listItem.featuredImage.url
    : props.placeholderImage.url;
});

const title = computed(() => {
  return props.listItem.featuredImage
    ? props.listItem.featuredImage.title
    : "Placeholder Image";
});

const description = computed(() => {
  return (
    props.listItem.agendaShortDescription ||
    props.listItem.generalHeader.description
  );
});
</script>
