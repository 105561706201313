<template>
  <vue3-autocounter
    ref="counter"
    v-observe-visibility="visibilityChanged"
    :start-amount="0"
    separator="."
  />
</template>

<script setup>
import { ref } from "vue";
import Vue3Autocounter from "vue3-autocounter";

const counter = ref(null);

const visibilityChanged = (isVisible) => {
  if (isVisible) {
    counter.value.start();
  }
};

defineExpose({ counter });
</script>
