import algoliasearch from "algoliasearch/lite";

const algoliaClient = algoliasearch(
  process.env.VUE_APP_ALGOLIASEARCH_APPLICATION_ID,
  process.env.VUE_APP_ALGOLIASEARCH_API_KEY
);

const algoliaClientService = algoliasearch(
  process.env.VUE_APP_ALGOLIASEARCH_APPLICATION_ID_KNOWLEDGE,
  process.env.VUE_APP_ALGOLIASEARCH_API_KEY_KNOWLEDGE
);
const getCharacterLength = (str) => {
  // The string iterator that is used here iterates over characters,
  // not mere code units
  return [...str].length;
};

const fakeEmptyRequest = (requests) => {
  return Promise.resolve({
    results: requests.map(() => ({
      hits: [],
      nbHits: 0,
      nbPages: 0,
      page: 0,
      processingTimeMS: 0,
    })),
  });
};

const handleSearch = (requests, client) => {
  if (
    requests.every(
      ({ params }) => !params.query || getCharacterLength(params.query) <= 2
    )
  ) {
    return fakeEmptyRequest(requests);
  }
  return client.search(requests);
};

const handleSearchPreloaded = (requests, client) => {
  if (
    requests.every(
      ({ params }) =>
        params.query &&
        getCharacterLength(params.query) === 1 &&
        getCharacterLength(params.query) === 2
    )
  ) {
    return fakeEmptyRequest(requests);
  }
  return client.search(requests);
};

export const searchClient = {
  ...algoliaClient,
  search(requests) {
    return handleSearch(requests, algoliaClient);
  },
};

export const searchClientPreloaded = {
  ...algoliaClient,
  search(requests) {
    return handleSearchPreloaded(requests, algoliaClient);
  },
};

export const searchClientService = {
  ...algoliaClientService,
  search(requests) {
    return handleSearch(requests, algoliaClientService);
  },
};

export const searchClientPreloadedService = {
  ...algoliaClientService,
  search(requests) {
    return handleSearchPreloaded(requests, algoliaClientService);
  },
};
