<template>
  <div>
    <div class="l-overview-search-bar">
      <div
        class="l-overview-search-bar__input l-overview-search-bar__input--search-box"
      >
        <span class="l-overview-search-bar__label">
          <div class="t-label">
            {{ t("Search") }}
          </div>
        </span>
        <debounced-search-box
          :search-index-suggestions="searchIndex + '_query_suggestions'"
        />
      </div>

      <div class="l-overview-search-bar__input">
        <span class="l-overview-search-bar__label">
          <div class="t-label">
            {{ t("Type") }}
          </div>
        </span>
        <algolia-multi-select attribute="searchCategories" />
      </div>

      <div class="l-overview-search-bar__input">
        <span class="l-overview-search-bar__label">
          <div class="t-label">
            {{ t("Category") }}
          </div>
        </span>
        <algolia-multi-select attribute="areasOfInterest.title" />
      </div>

      <div class="l-overview-search-bar__input">
        <div class="l-overview-search-bar__reset-button">
          <clear-refinements-button />
        </div>
      </div>
    </div>

    <overview-results-wrapper
      :extra-blocks="extraBlocks"
      :no-results-message="noResultsMessage"
    />

    <pagination-component is-centered />

    <div class="u-hidden-tablet-landscape">
      <refinement-component
        :filter-title="'filterTitle'"
        :reset-button="'resetButton'"
        :refinements="refinements"
        :seach-index="searchIndex"
      />
    </div>
  </div>
</template>

<script setup>
import { t } from "../../filters";
import { ref } from "vue";

defineProps({
  searchIndex: {
    type: String,
    required: true,
  },
  searchBaseUrl: {
    type: String,
    required: true,
  },
  extraBlocks: {
    type: Array,
    default: () => [],
  },
  noResultsMessage: {
    type: String,
    required: true,
  },
});

const refinements = ref([
  {
    attribute: "searchCategories",
    title: t("Kind"),
  },
  {
    attribute: "areasOfInterest.title",
    title: t("Category"),
  },
]);
</script>

<style lang="scss" scoped>
.l-overview-search-bar {
  display: none;

  @include tablet-landscape {
    display: flex;
    row-gap: 12px;
    column-gap: 40px;
    flex-wrap: wrap;
    padding-top: 32px;
  }

  &__input--search-box {
    flex-grow: 1;

    @include tablet {
      flex-grow: 0;
    }
  }

  &__input {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &__reset-button {
    height: 44px;
    display: flex;
    align-items: center;
  }

  @include tablet-landscape {
    flex-wrap: nowrap;

    &__input--search-box {
      width: auto;
    }
  }

  &__label {
    display: block;
    margin-bottom: 4px;

    @include tablet-landscape {
      margin-bottom: 8px;
    }
  }
}
</style>
