<template>
  <div class="updates__item">
    <div class="updates__visual">
      <div class="image-container">
        <a :href="item.url">
          <img :src="image" :alt="title" class="updates__image" />
        </a>
      </div>
    </div>

    <div class="updates__content">
      <h6 v-if="date" class="is-text">
        {{ date }}
      </h6>

      <a :href="item.url" class="updates__title-link">
        <div class="updates__title">
          {{ item.title }}
        </div>
      </a>

      <!-- eslint-disable-next-line vue/no-v-html -->
      <p class="updates__paragraph" v-html="item.shortDescription" />

      <div class="updates__details">
        <a
          :href="item.url"
          class="text-button__uppercase text-button__uppercase--underline"
        >
          {{ labels.readMore }}
        </a>

        <template v-if="item.areasOfInterest && item.areasOfInterest">
          <ul class="tags tags--justify-right">
            <li
              v-for="tag in item.areasOfInterest.slice(0, 2)"
              :key="tag.slug"
              class="tags__item tags__item--small tags__item--uppercase tags__item--micro tags__item--active"
            >
              {{ tag.title }}
            </li>
          </ul>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  placeholderImage: {
    type: String,
    required: true,
  },
  item: {
    type: Object,
    required: true,
  },
  labels: {
    required: true,
    type: Object,
  },
});

const image = computed(() => {
  if (
    "featuredImageDesktop" in props.item &&
    props.item.featuredImageDesktop.url
  ) {
    return props.item.featuredImageDesktop.url;
  }

  if ("featuredImage" in props.item && props.item.featuredImage.url) {
    return props.item.featuredImage.url;
  }

  return props.placeholderImage;
});

const title = computed(() => {
  return props.item.title ? props.item.title : "Placeholder Image";
});

const date = computed(() => {
  switch (props.item.type) {
    case "agendaDetail":
      return props.item.agendaEventStartDate;
    case "newsDetail":
      return props.item.datePublished;
    default:
      return null;
  }
});
</script>
