<template>
  <div class="form__select">
    <input v-model="value" type="hidden" :name="name" />
    <input
      v-model="input"
      type="text"
      class="form__select-input"
      :class="{ 'form__select-input--open': showList }"
      :name="`${name}-placeholder`"
      @click="handleInputClick"
      @focus="handleFocus"
      @blur="handleBlur"
      @keypress="searching = true"
    />
    <span
      class="form__input-icon"
      :class="{ 'form__input-icon--open': showList }"
      @click="handleIconClick"
    ></span>
    <ul v-show="showList" class="form__select-list">
      <li
        v-for="(option, index) in filteredOptions"
        :key="index"
        class="form__select-option"
        :class="{ 'form__select-option--selected': input === option.label }"
        @click="handleOptionClick(option)"
      >
        {{ option.label }}
      </li>
    </ul>
  </div>
</template>

<script setup>
import { ref, computed, onBeforeMount } from "vue";

const props = defineProps({
  options: {
    type: Array,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  initValue: {
    type: String,
    required: true,
  },
});

const input = ref("");
const value = ref("");
const showList = ref(false);
const searching = ref(false);

const filteredOptions = computed(() => {
  if (!input.value) {
    return props.options;
  }

  if (searching.value) {
    return props.options.filter((option) =>
      option.label.toLowerCase().includes(input.value.toLowerCase())
    );
  }

  return props.options;
});

const getLabelForValue = (value) => {
  const option = props.options.find((option) => option.value === value);

  if (!option) {
    return props.initValue;
  }

  return option.label;
};

const handleOptionClick = (option) => {
  input.value = option.label;
  value.value = option.value;
  showList.value = false;
};

const handleBlur = () => {
  // Dit moet beter!
  setTimeout(() => {
    showList.value = false;
    searching.value = false;
  }, 100);
};

const handleFocus = () => {
  showList.value = true;
};

const handleInputClick = () => {
  showList.value = true;
};

const handleIconClick = () => {
  showList.value = !showList.value;
};

onBeforeMount(() => {
  value.value = props.initValue;
  input.value = getLabelForValue(props.initValue);
});
</script>
