<template>
  <button
    :class="['c-button', { 'c-button--active': isActive }]"
    @click="emit('click', $event)"
  >
    <slot />
  </button>
</template>

<script setup>
defineProps({
  isActive: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["click"]);
</script>
