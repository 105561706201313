const stringToUri = (string) => {
  return string.replace(/[^\w]|_/g, "-").toLowerCase();
};

const setLanguageUrl = (lang) => {
  switch (lang) {
    case "EN":
      return "/en/service-and-contact/question/";
    case "DE":
      return "/de/service-und-kontakt/fragen/";
    case "NL":
      return "/service-en-contact/vraag/";
    default:
      return "/service-en-contact/vraag/";
  }
};

const createLink = (title, id, lang) => {
  const url = window.location.origin;
  const language = setLanguageUrl(lang);
  return `${url}${language}${id}/${stringToUri(title)}`;
};

export { stringToUri, setLanguageUrl, createLink };
