<template>
  <div class="latest-news-block__main">
    <div class="latest-news-block__items">
      <div class="latest-news-block__item">
        <div class="latest-news-block__item-image">
          <transition-group
            tag="figure"
            class="latest-news-block__image-container"
            name="fade"
          >
            <img
              v-for="(item, index) in items"
              v-show="index === selectedIndex"
              :key="`featured-image-${index}`"
              class="object-fit latest-news-block__image"
              :src="item.featuredImage.url"
            />
          </transition-group>
        </div>
        <div class="latest-news-block__item-summary">
          <h6 class="is-text">
            {{ selectedItem.dateDisplay }}
          </h6>
          <h3 class="latest-news-block__item-title">
            {{ selectedItem.title }}
          </h3>
          <p>
            {{ selectedItem.shortDescription }}
          </p>
          <div class="latest-news-block__item-cta">
            <a
              :href="selectedItem.url"
              class="text-button__uppercase text-button__uppercase--underline"
            >
              {{ selectedItem.readMoreText }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="latest-news-block__tab-list">
      <div
        v-for="(item, index) in items"
        :key="`latest-news-tab-${index}`"
        class="latest-news-block__tab-container"
      >
        <a
          class="latest-news-block__tab"
          :class="{ 'latest-news-block__tab--active': index === selectedIndex }"
          @click="selectedIndex = index"
        >
          <h6 class="is-text">{{ item.dateDisplay }}</h6>
          <div class="latest-news-block__tab-title">
            {{ item.shortTitle }}
          </div>
        </a>
      </div>
    </div>

    <div class="latest-news-block__tab-list-mobile">
      <a
        v-for="(item, index) in items"
        :key="`latest-news-tab-mobile-${index}`"
        class="latest-news-block__tab-mobile"
        :class="{ 'is-active': index === selectedIndex }"
        @click="selectedIndex = index"
      >
        {{ index + 1 }}
      </a>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";

const props = defineProps({
  items: {
    required: true,
    type: Array,
  },
});

const selectedIndex = ref(0);

const selectedItem = computed(() => {
  return props.items[selectedIndex.value];
});
</script>
