<template>
  <ul class="accordion" :class="{ 'accordion--active': active }">
    <slot></slot>
  </ul>
</template>

<script setup>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";

const store = useStore();

const props = defineProps({
  accordionName: {
    required: true,
    type: String,
  },
  initState: {
    required: false,
    type: Object,
    default: () => ({
      0: "",
      1: "",
    }),
  },
});

const active = computed(() => {
  return store.getters.isAccordionActive(props.accordionName);
});

onMounted(() => {
  store.commit("initAccordion", {
    accordionName: props.accordionName,
    initState: props.initState,
  });
});
</script>
