<template>
  <span data-observe>
    <div class="image-modal">
      <div
        ref="modal"
        class="image-modal__modal"
        :class="{ 'image-modal__modal--open': modalOpen }"
      >
        <div class="image-modal__background" @click="closeModal"></div>

        <button class="image-modal__close" @click="closeModal">✕</button>

        <div class="image-modal__modal-inner" @click="closeModal">
          <slot name="modal"></slot>
        </div>
      </div>

      <div class="image-modal__click" @click="openModal()">
        <slot name="clickable"></slot>
      </div>
    </div>
  </span>
</template>

<script setup>
import { ref } from "vue";

const modalOpen = ref(false);

const openModal = () => {
  modalOpen.value = true;
};

const closeModal = (event) => {
  if (event.target.tagName === "IMG") return;

  modalOpen.value = false;
};
</script>
