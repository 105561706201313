<template>
  <div class="u-margin-bottom-small u-flex">
    <svg-component name="chevron-left"></svg-component>
    <span class="u-margin-left-very-tiny">
      <a class="t-text t-text--title" :href="backUrl">
        <slot />
      </a>
    </span>
  </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  parent: {
    type: String,
    required: true,
  },
});

const backUrl = computed(() => {
  if (!document.referrer.includes(`${props.parent}?`)) return props.parent;

  return document.referrer;
});
</script>
