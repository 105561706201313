<template>
  <modal-wrapper :is-open="open">
    <template #default="{ closeModal }">
      <slot :close-modal="closeModal" />
    </template>
  </modal-wrapper>
</template>

<script setup>
import { ref, onBeforeMount } from "vue";
const props = defineProps({
  storageKey: {
    type: String,
    required: true,
  },
});

const open = ref(false);

onBeforeMount(() => {
  if (!props.storageKey) {
    open.value = true;
    return;
  }

  open.value = !JSON.parse(localStorage.getItem(props.storageKey));
  localStorage.setItem(props.storageKey, "true");
});
</script>
