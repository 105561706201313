<template>
  <div class="agenda-index" :class="{ 'agenda-index--slim': slim }">
    <div class="agenda-index__sidebar">
      <agenda-index-sidebar-calendar
        :slim="slim"
        :year-list="yearList"
        :month-list="monthList"
        :filter-month="selectedMonth"
        :filter-year="initialYear"
        :overview-url="overviewUrl"
        :labels="labels"
        @select-month="setSelectedMonth"
      />
    </div>
    <div class="agenda-index__main">
      <agenda-index-content-list
        :placeholder-image="placeholderImage"
        :slim="slim"
        :language="language"
        :filter-month="selectedMonth"
        :filter-year="initialYear"
        :overview-url="overviewUrl"
        :labels="labels"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";

const props = defineProps({
  placeholderImage: {
    type: String,
    required: true,
  },
  overviewUrl: {
    type: String,
    required: false,
    default: "",
  },
  slim: {
    default: false,
    type: Boolean,
  },
  language: {
    required: true,
    type: String,
  },
  labels: {
    required: true,
    type: Object,
  },
  initialMonth: {
    type: Number,
    default: null,
  },
  initialYear: {
    type: Number,
    default: null,
  },
  yearList: {
    required: true,
    type: Array,
  },
  monthList: {
    required: true,
    type: Object,
  },
});

const selectedMonth = ref(null);
const selectedYear = ref(null);

onMounted(() => {
  selectedMonth.value = props.initialMonth;
  selectedYear.value = props.initialYear;
});

function setSelectedMonth(month) {
  selectedMonth.value = month;
}
</script>
