<template>
  <div ref="container" class="c-testimonials__inner">
    <slot
      ref="slides"
      name="slides"
      :on-prev-click="prevClickHandler"
      :on-next-click="nextClickHandler"
    />
    <slot
      ref="controls"
      name="controls"
      :on-prev-click="prevClickHandler"
      :on-next-click="nextClickHandler"
    />
  </div>
</template>

<script setup>
import { gsap } from "gsap";
import { debounce } from "lodash";
import { ref, onMounted, onUnmounted, reactive } from "vue";

const xDown = ref(null);
const yDown = ref(null);

const activeSlide = ref(0);
const height = ref(null);
const queue = ref([]);
const reversed = ref(false);

const timeline = gsap.timeline();

const container = ref(null);

const config = reactive({
  slides: null,
  length: null,
  queueLimit: 1,
});

const modulo = (number, mod) => {
  let result = number % mod;
  return result < 0 ? (result += mod) : result;
};

const getSlides = () => {
  return container.value.children;
};

const setRotation = () => {
  return reversed.value ? 10 : -10;
};

const setX = () => {
  return reversed.value ? 60 : -60;
};

const killTimeline = () => {
  if (timeline.value) {
    timeline.kill();
    timeline.value = null; // Reset the timeline to ensure it's fully disposed of
  }
};

const setCarouselHeight = () => {
  const largestSlide = Object.values(config.slides).reduce(
    (previousValue, currentValue) => {
      return currentValue.offsetHeight > previousValue.offsetHeight
        ? currentValue
        : previousValue;
    }
  );

  height.value = `${largestSlide.offsetHeight}px`;
};

const showNextSlide = () => {
  const slide = config.slides[activeSlide.value];
  const children = slide.querySelectorAll(".gsap-slide-child");
  const flower = slide.querySelector(".gsap-leaf-left").parentElement;

  slide.classList.remove("controls-hidden");

  timeline.fromTo(
    flower,
    {
      rotation: setRotation(),
      scale: 0.95,
      autoAlpha: 0,
    },
    {
      autoAlpha: 1,
      rotation: 0,
      scale: 1,
      duration: 1.25,
      // ease: "back",
    }
  );

  timeline.fromTo(
    children,
    {
      autoAlpha: 0,
      x: setX(),
    },
    {
      autoAlpha: 1,
      x: 0,
      stagger: 0.06,
      duration: 0.85,
      ease: "SqueezyInOut",
    },
    "<"
  );
};

const setupCarousel = () => {
  config.slides = getSlides();

  if (!config.slides) return;

  config.length = Object.keys(config.slides).length - 1;

  setCarouselHeight();
  showNextSlide();
};

const getTouches = (e) => {
  return e.touches;
};

const hidePreviousSlide = () => {
  const slide = config.slides[activeSlide.value];
  const children = slide.querySelectorAll(".gsap-slide-child");

  slide.classList.add("controls-hidden");

  timeline.fromTo(
    children,
    {
      autoAlpha: 1,
    },
    {
      autoAlpha: 0,
      duration: 0.25,
      ease: "power3.out",
    }
  );
};

const nextSlide = () => {
  reversed.value = true;
  hidePreviousSlide();
  activeSlide.value = modulo(activeSlide.value + 1, config.length);
  showNextSlide();
};

const previousSlide = () => {
  reversed.value = false;
  hidePreviousSlide();
  activeSlide.value = modulo(activeSlide.value - 1, config.length);
  showNextSlide();
};

const emptyQueue = () => {
  queue.value = [];
};

const queueNextSlide = (fn) => {
  if (queue.value.length < config.queueLimit) {
    queue.value.push(fn);

    timeline.to(null, {
      onStart: fn,
      onComplete: () => emptyQueue(),
    });
  }
};

const prevClickHandler = () => {
  timeline.isActive() ? queueNextSlide(previousSlide) : previousSlide();
};

const nextClickHandler = () => {
  timeline.isActive() ? queueNextSlide(nextSlide) : nextSlide();
};

const touchStartHandler = (e) => {
  const { clientX, clientY } = getTouches(e)[0];

  xDown.value = clientX;
  yDown.value = clientY;
};

const touchMoveHandler = (e) => {
  if (!xDown.value || !yDown.value) return;

  const xUp = e.touches[0].clientX;
  const yUp = e.touches[0].clientY;

  const xDiff = xDown.value - xUp;
  const yDiff = yDown.value - yUp;

  if (Math.abs(xDiff) > Math.abs(yDiff)) {
    if (xDiff > 0) {
      timeline.isActive() ? queueNextSlide(nextSlide) : nextSlide();
    } else {
      timeline.isActive() ? queueNextSlide(previousSlide) : previousSlide();
    }
  }

  xDown.value = null;
  yDown.value = null;
};

const resizeHandler = () => {
  setCarouselHeight();
};

const setupEventListeners = () => {
  window.addEventListener("resize", debounce(resizeHandler, 300));

  container.value.addEventListener("touchstart", touchStartHandler, {
    passive: true,
  });

  container.value.addEventListener("touchmove", touchMoveHandler, {
    passive: true,
  });
};

const removeEventListeners = () => {
  window.removeEventListener("resize", debounce(resizeHandler, 300));

  container.value.removeEventListener("touchstart", touchStartHandler);

  container.value.removeEventListener("touchmove", touchMoveHandler);
};

onMounted(() => {
  setupEventListeners();
  setupCarousel();
});

onUnmounted(() => {
  removeEventListeners();
  killTimeline();
});
</script>

<style scoped>
.c-testimonials__inner {
  height: v-bind(height);
}
</style>
