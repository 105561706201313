<template>
  <button class="c-button-solid">
    <ais-stats>
      <template #default="{ nbHits }">
        <svg-component name="filter" class="u-mr-8"></svg-component>
        {{ resultsMessage(nbHits) }}
      </template>
    </ais-stats>
  </button>
</template>

<script setup>
import { t } from "../../filters";

const resultsMessage = (nbHits) => {
  if (nbHits === 0) {
    return t("No results");
  }
  if (nbHits === 1) {
    return t("Show result");
  }

  return t("Show {number} results", {
    params: { number: nbHits },
  });
};
</script>
