<template>
  <div
    :class="[
      'c-input-radio',
      { 'c-input-radio--has-inline-options': hasOptionsInline },
    ]"
  >
    <LabelSimpleComponent
      v-if="label"
      :name="name"
      class="c-input-radio__label"
    >
      {{ label }}
    </LabelSimpleComponent>

    <div
      v-for="option in options"
      :key="`${name}-${option.value}`"
      :class="[
        'c-input-radio__option',
        { 'c-input-radio__option--selected': value === option.value },
      ]"
    >
      <input
        :id="`${name}-${option.value}`"
        v-model="value"
        type="radio"
        :name="name"
        :value="option.value"
        class="c-input-radio__input"
        @input="handleInput"
      />

      <label
        :for="`${name}-${option.value}`"
        class="c-input-radio__option-label"
      >
        {{ option.label }}
      </label>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  initialValue: {
    type: [String, Number, Boolean],
    default: null,
  },
  label: {
    type: String,
    default: "",
  },
  options: {
    type: Array,
    required: true,
  },
  hasOptionsInline: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:modelValue"]);

const value = ref(null);

onMounted(() => {
  value.value = props.initialValue;
});

const handleInput = (event) => {
  let inputValue = null;

  if (event.target.value === "true" || event.target.value === "1") {
    inputValue = true;
  } else if (
    event.target.value === "false" ||
    event.target.value === "0" ||
    event.target.value === null
  ) {
    inputValue = false;
  } else {
    inputValue = event.target.value;
  }
  emit("update:modelValue", inputValue);
};
</script>

<style lang="scss">
.c-input-radio {
  &__option {
    border: 1px solid grey;
    background-color: $white;
    margin-bottom: 16px;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    padding-left: 12px;
    display: flex;
    align-items: center;

    &--selected {
      border-color: $green;
    }
  }

  &__input {
    cursor: pointer;
    margin-right: 8px;
  }

  &__option {
    min-width: 100px;
  }

  &__option-label {
    cursor: pointer;
    padding-right: 16px;
    height: 48px;
    flex: 1;
    line-height: 48px;
  }

  &--has-inline-options {
    display: flex;
    flex-wrap: wrap;
    column-gap: 16px;
  }

  &--has-inline-options &__label {
    width: 100%;
  }
}
</style>
