<template>
  <div class="scrollable-content">
    <nav
      ref="nav"
      class="scrollable-content__navigation scrollable-content__navigation--sticky"
      :class="scrollableNavigationClass"
    >
      <ul class="scrollable-content__navigation-list">
        <li
          v-for="item in navItems"
          :key="item.id"
          class="scrollable-content__navigation-item"
          :class="{
            'scrollable-content__navigation-item--active':
              item.id == activeItemId,
          }"
          @click="setActiveId(item.id)"
        >
          <a :href="`#${item.id}`" class="scrollable-content__navigation-title">
            {{ item.title }}
          </a>
        </li>
      </ul>
    </nav>
    <div class="scrollable-content__content">
      <slot />
    </div>
  </div>
</template>

<script setup>
import { throttle, debounce } from "lodash";

import { ref, computed, onMounted, onUnmounted } from "vue";

const props = defineProps({
  navItems: {
    required: true,
    type: Array,
  },
  scrollableNavigationClass: {
    required: false,
    type: String,
    default: "",
  },
});

const activeItemId = ref(props.navItems[0].id);
const breakpoint = ref(1024);
const breakpointTablet = ref(768);
const device = ref(null);
const handlingNavEvent = ref(false);
const nav = ref(null);

const blockId = computed(() => {
  return `#${activeItemId.value}`;
});

const isTouchDevice = computed(() => {
  return device.value === "mobile" || device.value === "tablet";
});

const offset = computed(() => {
  const mainNavigationMobile = document.querySelector(".navigation-mobile");
  const mainNavigation = document.querySelector(".navigation");

  return isTouchDevice.value
    ? mainNavigationMobile.clientHeight + nav.value.clientHeight + 12
    : mainNavigation.clientHeight + 24;
});

const handleScroll = () => {
  console.log("test");
  if (handlingNavEvent.value) {
    return;
  }

  const activeItem = props.navItems.reduce((acc, item) => {
    const block = document.getElementById(`${item.id}`);
    if (block.getBoundingClientRect().top <= offset.value + 1) {
      return item;
    }
    return acc;
  }, props.navItems[0]);

  if (activeItemId.value === activeItem.id) {
    return;
  }

  activeItemId.value = activeItem.id;
  history.replaceState(null, null, blockId.value);
};

const handleScrollThrottled = throttle(() => {
  handleScroll();
}, 150);

const setDevice = () => {
  device.value = null;

  if (window.innerWidth < breakpointTablet.value) {
    device.value = "mobile";
  } else if (window.innerWidth < breakpoint.value) {
    device.value = "tablet";
  }
};

const reset = () => {
  setDevice();
};

const stopNavHandleTimer = () => {
  handlingNavEvent.value = false;
};

const stopNavHandleTimerDebounced = debounce(stopNavHandleTimer, 750);

const startNavHandleTimer = () => {
  handlingNavEvent.value = true;

  stopNavHandleTimerDebounced();
};

const setActiveId = (id) => {
  startNavHandleTimer();

  activeItemId.value = id;
};

onMounted(() => {
  reset();

  window.addEventListener("scroll", handleScrollThrottled);

  window.addEventListener("resize", () => {
    reset();
  });
});

onUnmounted(() => {
  window.removeEventListener("scroll", handleScrollThrottled);
});
</script>
