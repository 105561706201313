<template>
  <section>
    <ais-stats v-slot="{ nbHits, query }">
      <p
        class="t-paragraph t-paragraph--big u-mb-24"
        :class="{ bold: !nbHits }"
      >
        {{ resultString(nbHits) }}<strong>{{ `'${query}'` }}</strong>
      </p>
    </ais-stats>

    <service-debounced-search-box
      :delay="500"
      :search-base-url="searchBaseUrl"
      :language="language"
    />

    <knowledge-articles-list class="u-mt-32 u-mb-32" :language="language" />

    <pagination-component is-centered />
  </section>
</template>

<script setup>
import { t } from "../../filters";

defineProps({
  searchBaseUrl: {
    type: String,
    default: "",
  },
  language: {
    type: String,
    required: true,
  },
});

const resultString = (number) => {
  if (number === 1) {
    return t("Resultaat {number} gevonden voor", {
      params: { number },
    });
  }

  return t("Resultaten {number} gevonden voor", {
    params: { number },
  });
};
</script>
