<template>
  <span class="dropdown-component" @mouseleave="isOpen = false">
    <span class="dropdown-component__button" @mouseover="isOpen = true">
      <slot name="button"></slot>
    </span>

    <div v-if="isOpen" class="dropdown-component__items">
      <slot name="items"></slot>
    </div>
  </span>
</template>

<script setup>
import { ref } from "vue";

const isOpen = ref(false);
</script>
