<template>
  <ais-hits v-slot="{ items }" :transform-items="transformItems">
    <list-component is-bordered :items="items" />
  </ais-hits>
</template>

<script setup>
import { createLink } from "../../serviceDetailLink";

const props = defineProps({
  language: {
    type: String,
    required: true,
  },
});

const transformItems = (items) => {
  return items.map((item) => {
    return {
      key: item.objectID,
      title: item.title,
      link: createLink(item.title, item.number, props.language),
    };
  });
};
</script>
