<template>
  <ais-instant-search
    :search-client="searchClient"
    :index-name="searchIndex + '_query_suggestions'"
    :future="{ preserveSharedStateOnUnmount: false }"
  >
    <ais-autocomplete v-slot="{ currentRefinement, indices, refine }">
      <div class="searchbar" :class="searchbarClassComputed">
        <div class="searchbar__group">
          <input
            ref="input"
            v-model="query"
            :placeholder="labels.search"
            type="search"
            name="search"
            class="searchbar__input"
            @focus="focus = true"
            @input="() => debounceInput(refine, query)"
            @keyup.enter="handleEnter"
          />
          <div class="searchbar__close" @click.stop="handleClickClose"></div>
          <div class="searchbar__content">
            <template v-if="currentRefinement && indices[0].hits.length">
              <div class="u-margin-bottom-tiny">
                <h6>
                  <slot name="label-hits"></slot>
                </h6>
              </div>
              <ul class="results u-margin-bottom-small">
                <li
                  v-for="hit in indices[0].hits"
                  :key="hit.objectID"
                  class="results__item"
                >
                  <a class="link link--decorated" :href="createURL(hit.query)">
                    <ais-highlight
                      attribute="query"
                      :hit="hit"
                      :class-names="{
                        'ais-Highlight': 'highlight',
                        'ais-Highlight-highlighted': 'highlight__thin',
                      }"
                    />
                  </a>
                </li>
              </ul>
            </template>
            <template v-else>
              <slot name="popular-terms"></slot>
              <main-navigation-recent-searches
                :search-base-url="searchBaseUrl"
                class="u-margin-bottom-small"
              >
                <slot name="label-history"></slot>
              </main-navigation-recent-searches>
            </template>
          </div>
        </div>
      </div>
    </ais-autocomplete>
  </ais-instant-search>
</template>

<script setup>
import { debounce } from "lodash";

import { searchClient } from "../../algoliaSearchClient";
import { ref, computed, watch } from "vue";

const props = defineProps({
  labels: {
    type: Object,
    required: true,
  },
  searchIndex: {
    type: String,
    required: true,
  },
  searchBaseUrl: {
    type: String,
    required: true,
  },
  activeItem: {
    type: [String, Boolean],
    required: false,
    default: "",
  },
  searchbarClass: {
    type: Object,
    required: false,
    default: () => ({}),
  },
});

const input = ref(null);

const emit = defineEmits(["closed"]);

const query = ref("");

const hasFocus = computed(() => {
  return props.activeItem === "searchbar" || props.activeItem === "menu-search";
});

const searchbarClassComputed = computed(() => ({
  "searchbar--focus": hasFocus.value,
  "searchbar--valid": query.value,
  ...props.searchbarClass,
}));

const handleClickClose = () => {
  query.value = "";
  emit("closed", true);
};

const createUrl = (query) => {
  return `${props.searchBaseUrl}?query=${encodeURIComponent(query)}`;
};

const handleEnter = () => {
  if (query.value) {
    window.location.href = createUrl(query.value);
  }
};

const focusInput = () => {
  input.value.focus();
};

const debounceInput = debounce((refine, query) => {
  if (query.length <= 2) {
    return;
  }
  refine(query.value);
}, 500);

watch(
  () => props.activeItem,
  (activeItem) => {
    if (activeItem === "menu-search") {
      focusInput();
    }
  }
);
</script>
