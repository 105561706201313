<template>
  <div ref="scrollOutRef">
    <slot />
  </div>
</template>

<script setup>
import ScrollOut from "scroll-out";
import { ref, onMounted, onUnmounted } from "vue";

let so;
const scrollOutRef = ref(null);

onMounted(() => {
  so = ScrollOut({
    targets: scrollOutRef.value,
    once: true,
    threshold: 0.3,
  });
});

onUnmounted(() => {
  so.teardown();
});
</script>
